import { CircularProgress, Dialog } from '@material-ui/core'
import {
    AddBox,
    ArrowDownward,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    Edit,
    FilterList,
    FirstPage,
    LastPage,
    SaveAlt,
    Search,
} from '@material-ui/icons'
import 'firebase/functions'
import MaterialTable, { Icons } from 'material-table'
import React, { forwardRef } from 'react'
import btnReport from '../../images/btn_report.png'
import { IImageProduct } from '../../model/IImageProduct'

const tableIcons: Icons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
        <ArrowDownward {...props} ref={ref} />
    )),
}

export default function ImageTable(title: string, dataArray: Array<any>) {
    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const reportImage = (rowData: IImageProduct) => {
        handleClickOpen()
        rowData.docRef
            .set(
                {
                    imagePath: 'report-image.png',
                },
                { merge: true },
            )
            .then(() => {
                handleClose()
                return
            })
            .catch((error) => {
                handleClose()
                alert(error.message)
            })
    }

    return (
        <div>
            <MaterialTable
                title={title}
                columns={[
                    {
                        field: 'url',
                        title: 'Image',
                        render: (rowData) => (
                            <img
                                src={rowData.imagePath}
                                style={{ width: 100, height: 100 }}
                                alt="Report"
                            />
                        ),
                    },
                    { field: 'name', title: 'Name' },
                ]}
                data={dataArray}
                icons={tableIcons}
                options={{
                    pageSize: 20,
                    actionsColumnIndex: -1,
                    exportButton: true,
                }}
                localization={{
                    header: {
                        actions: 'Action',
                    },
                }}
                actions={[
                    {
                        icon: () => (
                            <img src={btnReport} width="120" alt="Report" />
                        ),
                        tooltip: 'Report Image',
                        onClick: (event, rowData) => reportImage(rowData),
                    },
                ]}
            />

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <CircularProgress style={{ margin: '20px' }} />
            </Dialog>
        </div>
    )
}
