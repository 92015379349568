import { CircularProgress, Container, Dialog, Grid } from '@material-ui/core'
import 'firebase/firestore'
import 'firebase/storage'
import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import firebase from '../../firebase'
import NavBar from '../component/navBar'
import useStyles from '../component/style'
import FacebookPageReportTable from './table'

interface IFacebookPage {
    pageId: string
    pageName: string

    bankAccountName: string
    LatestLive: string
    livePath: string
}

type Timestamp = {
    seconds: number
    nanoseconds: number
}
type FilterDate = {
    fromdate: string | null
    todate: string | null
}

export default function ReportPage(props: any) {
    const classes = useStyles()
    const history = useHistory()
    const { search } = useLocation()
    const getFromdateDefault = () => '2020-04-21'
    const getTodateDefault = () => moment().format('YYYY-MM-DD')

    const getFromdateLabel = (fromdate: string | null) =>
        fromdate ? dateFormateYearMonthDay(fromdate) : getFromdateDefault()

    const getTodateLabel = (todate: string | null) =>
        todate ? dateFormateYearMonthDay(todate) : getTodateDefault()

    const queryParams = useMemo(() => new URLSearchParams(search), [search])

    const [open, setOpen] = React.useState<boolean>(false)

    const [fromdateLabel, setFromdateLabel] = React.useState<string>('')
    const [todateLabel, setTodateLabel] = React.useState<string>('')

    const [facebookPages, setfacebookPage] = useState<IFacebookPage[]>([
        {
            pageId: '',
            pageName: '',
            bankAccountName: '',
            LatestLive: '',
            livePath: '',
        },
    ])

    useEffect(() => {
        handleClickOpen()
        const [fromdate, todate] = [
            getFromdateLabel(queryParams.get('fromdate')),
            getTodateLabel(queryParams.get('todate')),
        ]
        setFromdateLabel(fromdate)
        setTodateLabel(todate)
        getData({ fromdate, todate })
    }, [queryParams])

    const converTimestampToDateString = (timestamp: Timestamp) => {
        return timestamp
            ? moment(timestamp.seconds * 1000).format('YYYY-MM-DD HH:mm:ss')
            : ''
    }

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const getData = useCallback(({ fromdate, todate }: FilterDate) => {
        console.log('fromdate ' + fromdate)
        console.log('todate ' + todate)
        const query = firebase.firestore().collection('pages')
        query.onSnapshot(async (querySnapshot) => {
            let filterPageIds: string[] | null = null
            if (fromdate || todate) {
                filterPageIds = await getFacebookPageIdLiveOnFilterDate({
                    fromdate,
                    todate,
                })
            }
            let facebookPages: IFacebookPage[] = _.reduce(
                querySnapshot.docs,
                (res, doc, index) => {
                    if (
                        !filterPageIds ||
                        (filterPageIds && filterPageIds.includes(doc.id))
                    ) {
                        const pageId = doc.id
                        const pageName = _.get(doc.data(), 'pageName', '-')
                        const bankAccountName = _.get(
                            doc.data(),
                            'bankAccounts[0].name',
                            '-',
                        )
                        const livePath = `${doc.ref.path}/lives`
                        const fbg = {
                            LatestLive: '-',
                            pageName,
                            pageId,
                            bankAccountName,
                            livePath,
                        } as IFacebookPage
                        res.push(fbg)
                    }
                    return res
                },
                [] as IFacebookPage[],
            )

            await Promise.all(
                _.map(facebookPages, (fbp) => {
                    const getLastLive = firebase
                        .firestore()
                        .collection(fbp.livePath)
                        .orderBy('liveStartTime', 'desc')
                        .limit(1)
                        .get()
                    getLastLive.then((res) => {
                        if (res.docs.length) {
                            const data = res.docs[0].data()
                            const liveStartTime = _.get(
                                data,
                                'liveStartTime',
                                '',
                            )
                            if (data.liveStartTime) {
                                fbp.LatestLive = converTimestampToDateString(
                                    liveStartTime,
                                )
                            }
                        }
                    })

                    return getLastLive
                }),
            )
            setfacebookPage(facebookPages)
            handleClose()
        })
    }, [])

    const getFacebookPageIdLiveOnFilterDate = async ({
        fromdate,
        todate,
    }: FilterDate) => {
        let queryFirebase = firebase.firestore().collectionGroup('lives')
        if (fromdate) {
            const _fromdate = new Date(
                moment(fromdate)
                    .add(-1, 'days')
                    .format('YYYY-MM-DD 23:59:59:999'),
            )
            queryFirebase = queryFirebase.where('liveStartTime', '>', _fromdate)
        }
        if (todate) {
            const _todate = new Date(
                moment(todate).add(1, 'days').format('YYYY-MM-DD 00:00:00:000'),
            )
            queryFirebase = queryFirebase.where('liveStartTime', '<', _todate)
        }
        const snapshot = await queryFirebase.get()
        const pageIds: string[] = _.chain(snapshot.docs)
            .map((doc) => _.get(doc, 'ref.parent.parent.id', ''))
            .uniq()
            .compact()
            .value()
        return pageIds
    }

    const dateFormateDayMonthYear = (dateString: string) => {
        return moment(`${dateString} 00:00:00`).format('DD-MM-YYYY')
    }

    const dateFormateYearMonthDay = (dateString: string) => {
        return dateString.split('-').reverse().join('-')
    }

    const onSubmitDate = (fromDate: String, toDate: String) => {
        const { pathname, search } = props.location
        const query = new URLSearchParams(search)
        query.set('fromdate', dateFormateDayMonthYear(fromDate as string))
        query.set('todate', dateFormateDayMonthYear(toDate as string))
        history.push({
            pathname,
            search: query.toString(),
        })
    }

    return (
        <Container>
            <NavBar title={'Report Facebook Page'} />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Container component="main">
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {FacebookPageReportTable(
                                'Report Facebook Page',
                                facebookPages,
                                onSubmitDate,
                                fromdateLabel,
                                todateLabel,
                            )}
                        </Grid>
                    </Grid>
                </Container>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <CircularProgress style={{ margin: '20px' }} />
                </Dialog>
            </main>
        </Container>
    )
}
